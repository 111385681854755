jQuery(function(t){var e=function(){var e=("https:"==location.protocol?"https:":"http:")+"//formoid.net/api/push",a=function(){var e=(/MSIE (\d+)\./.exec(navigator.userAgent)||[0,0])[1]
return 8==e||9==e&&"file:"!=location.protocol?function(e,a){var n=new XDomainRequest,r=t.Deferred()
return n.open(a.type,e),n.onload=function(){r.resolve(this.responseText)},n.onerror=function(){r.reject()},n.send(a.data),r}:(t.support.cors=!0,t.ajax)}(),n=function(t,e){return t="__"+t+"__",e.length?(this[t]=e[0],this):this[t]},r=function(e,a,n){return t.each(n,function(t,n){e[n]=function(){return a[n].apply(a,arguments)}}),e},i=function(t){t=t||{},this.__email__=t.email||"",this.__title__=t.title||"",this.__data__=t.data||[]}
return i.prototype.email=function(t){return n.call(this,"email",arguments)},i.prototype.title=function(t){return n.call(this,"title",arguments)},i.prototype.data=function(t){return n.call(this,"data",arguments)},i.prototype.send=function(n,i){var o=r(t.Deferred(),this,["email","title","data","send"])
return i&&(i.call(this,o),"pending"!=o.state())?o:(a(e,{type:"POST",data:JSON.stringify({email:this.__email__,form:{title:this.__title__,data:arguments.length?n:this.__data__}})}).done(function(t){try{var e=JSON.parse(t)
e.error?o.reject(e.error):o.resolve(e.response)}catch(a){o.reject("Incorrect server response.")}}).fail(function(){var t="Failed to query the server. "
t+="onLine"in navigator&&!navigator.onLine?"No connection to the Internet.":"Check the connection and try again.",o.reject(t)}),o)},{Form:function(t){return new i(t)}}}(),a=function(e){if(e.checkValidity)return e.checkValidity()
var a=!0,n=t(e).val(),r=t(e).attr("type")
return n?a=!("email"===r&&!/^([^@]+?)@(([a-z0-9]-*)*[a-z0-9]+\.)+([a-z0-9]+)$/i.test(n)):t(e).attr("required")&&(a=!1),t(e)[(a?"remove":"add")+"Class"]("form-invalid"),a}
t('[data-form-type="formoid"]').each(function(){var n,r=t(this),i=r.is("form")?r:r.find("form"),o=r.find("[data-form-alert]"),s=r.is("[data-form-title]")?r:r.find("[data-form-title]"),l=r.find('[type="submit"]'),c=o.attr("data-success")||o.find("[data-form-alert-success]").html()
l.html('<span class="btn-text">'+l.html()+'</span><i class="btn-loader"></i>').click(function(){i.addClass("form-active")}),i.submit(function(d){if(d.preventDefault(),i.addClass("form-active"),!l.hasClass("btn-loading")){var f=!0,u=[]
n=n||e.Form({email:r.find("[data-form-email]").val(),title:s.attr("data-form-title")||s.text()}),o.html(""),r.find("[data-form-field]").each(function(){a(this)||(f=!1),u.push([t(this).attr("data-form-field")||t(this).attr("name"),t(this).val()])}),f&&(l.addClass("btn-loading").prop("disabled",!0),n.send(u).done(function(e){i.removeClass("form-active"),r.find("[data-form-field]").val(""),o.append(t('<div class="alert alert-form alert-success text-xs-center"/>').text(c||e))}).fail(function(e){o.append(t('<div class="alert alert-form alert-danger text-xs-center"/>').text(e))}).always(function(){l.removeClass("btn-loading").prop("disabled",!1)}))}})})})
