// Other items are in the "pre-req file" (JQuery, Tether), needed before Bootstrap
require('./ph_script/require-jquery-and-global/require-jquery-and-global.js');
require('./bootstrap/js/bootstrap.min.js');
require('./viewport-checker/jquery.viewportchecker.js');
require('./dropdown/js/script.min.js');
require('./touch-swipe/jquery.touch-swipe.min.js');
//2020.07.21,BN: disabled because `localStorage` calls are failing in incognito or iframe-different-domain
//require('./smooth-scroll/smooth-scroll.js');
require('./theme/js/script.js');
require('./formoid/formoid.min.js');
axios = require ('./axios/axios.min');

// External requirements
require('./app_include_external_req');
require('./bootstrap-table/bootstrap-table'); // modified relative to .min

// Non-external
check_file_size = require('./ph_script/check-file-size/check-file-size.js');
tab_helper = require('./ph_script/tab-helper/tab-helper.js');
bootstrap_table_helper = require('./ph_script/bootstrap-table-helper/bootstrap-table-helper.js');
autosize = require('./ph_script/autosize/autosize.js');
spinner_helper = require('./ph_script/spinner-helper/spinner-helper');


// Specific screens
project_article = require('./ph_script/screens/project-article.js');
video_upload = require('./ph_script/screens/video-upload');
video_detailed_comments = require('./ph_script/screens/video-detailed-comments');
landing_page = require('./ph_script/screens/landing-page');

// Images
// noinspection JSUnusedLocalSymbols
const i1 = require('./images/pitch-cycle.gif');
require('./images/icons/conversion_landing_page.png');
require('./images/PitchHub-15pct.png');
require('./images/pitchhub-25pct.gif');
require('./images/pitchhub-25pct.png');
require('./images/PitchHub-nt-15pct-.png');
require('./images/spokes-light-1-2000x1338.png');
require('./images/animated/working_blue_32.gif');
require('./images/animated/working_blue_48.gif');
require('./images/PitchHub-6_steps-console.png');
require('./images/PoweredByPitchHub_01.png');

// Logo 2.0
// PNG - normal
require('./images/logo_2.0/png/pitchhub-blue-15pct.png');
require('./images/logo_2.0/png/pitchhub-blue-25pct.png');
require('./images/logo_2.0/png/pitchhub-blue-50pct.png');
require('./images/logo_2.0/png/pitchhub-blue-100pct.png');
require('./images/logo_2.0/png/pitchhub-blue-text-15pct.png');
require('./images/logo_2.0/png/pitchhub-blue-text-25pct.png');
require('./images/logo_2.0/png/pitchhub-blue-text-50pct.png');
require('./images/logo_2.0/png/pitchhub-blue-text-100pct.png');
// PNG - white circular background
require('./images/logo_2.0/png/pitchhub-blue-white-circle-10pct.png');
require('./images/logo_2.0/png/pitchhub-blue-white-circle-15pct.png');
require('./images/logo_2.0/png/pitchhub-blue-white-circle-25pct.png');
require('./images/logo_2.0/png/pitchhub-blue-white-circle-50pct.png');
require('./images/logo_2.0/png/pitchhub-blue-white-circle-100pct.png');
// PNG - white circular background -- no gaps
require('./images/logo_2.0/png/PitchHub-favicon-gradient.png');
require('./images/logo_2.0/png/PitchHub-favicon-nogaps.png');

// SVG
require('.//images/logo_2.0/svg/pitchhub-white.svg');
require('.//images/logo_2.0/svg/pitchhub-white-text.svg');
require('.//images/logo_2.0/svg/pitchhub-blue.svg');
require('.//images/logo_2.0/svg/pitchhub-blue-text.svg');

